import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "reactstrap";
import Helmet from "../components/Helmet/Helmet";
import { useParams } from "react-router-dom";
import { Carousel }  from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faCogs, faGasPump, faClock, faMapMarkerAlt, faSlidersH } from '@fortawesome/free-solid-svg-icons';

import "../styles/car-details.css";

const CarDetails = () => {
  const { id } = useParams();
  const [car, setCar] = useState(null);
  const [error, setError] = useState(null);  // Optional: handle errors

  useEffect(() => {
    const fetchDataFromBackend = async () => {
      try {
        // Replace the Firestore fetching code with a fetch to your backend
        const response = await fetch(`https://automatrulatebackend-246f1b5aea0c.herokuapp.com/cars/${id}`);
        
        if (!response.ok) {
          throw new Error('Car not found');
        }
        
        const carData = await response.json();
        
        // Assuming the backend sends a car object with an 'images' field (which may be URLs or file paths)
        const images = carData.image_paths || [];

        // Set the state with the fetched car data
        setCar({ id, ...carData, images });
      } catch (err) {
        console.error("Error fetching car data:", err);
        setError("Failed to fetch car details.");
      }
    };

    fetchDataFromBackend();
  }, [id]);

  if (!car) return <div>Se incarca...</div>;

  return (
    <Helmet title={car.make}>
      <section>
        <Container style={{ maxWidth:'1620px' }}>
          <Row>
            <Col lg="9">
              <Carousel showThumbs={false} showStatus={false} showIndicators={false} showCaptions={false}>
                {car.image_paths.map((image, index) => (
                  <div key={index} className="carousel-image-container-details">
                    <img src={image} alt={`Car ${car.model}`} className="carousel-image-details" />
                  </div>
                ))}
              </Carousel>
            </Col>



            <Col lg="3" >
              <div className="car__info">

                <div className="section__title d-flex align-items-center justify-content-around">
                  <h2 className="section__title align-items-center justify-content-around mt-3 mb-4">{car.make} {car.model}</h2>
                </div>

                <div className="d-flex align-items-center justify-content-around gap-5 mb-4">
                  <h6 className="rent__price fw-bold fs-4">
                    Pret - €{car.price}
                  </h6>
                </div>

                <div className="car__item-info d-flex align-items-center justify-content-around mt-3 mb-4">
                  <span className=" d-flex align-items-center gap-1">
                    <FontAwesomeIcon icon={faCar} style={{ color: "#f9a826", fontSize: "24px" }} /> {car.body}
                  </span>

                  <span className="d-flex align-items-center gap-1">
                    <FontAwesomeIcon icon={faCogs} style={{ color: "#f9a826", fontSize: "24px" }} /> {car.engine}
                  </span>
                </div>

                <div className="car__item-info d-flex align-items-center justify-content-around mt-3 mb-4">
                  <span className=" d-flex align-items-center gap-1">
                    <FontAwesomeIcon icon={faGasPump} style={{ color: "#f9a826", fontSize: "24px" }} /> {car.fuel}
                  </span>

                  <span className=" d-flex align-items-center gap-1">
                    <FontAwesomeIcon icon={faMapMarkerAlt} style={{ color: "#f9a826", fontSize: "24px" }} /> {car.mileage} km
                  </span>
                </div>

                <div className="car__item-info d-flex align-items-center justify-content-around mt-3 mb-4">
                  <span className=" d-flex align-items-center gap-1">
                    <FontAwesomeIcon icon={faSlidersH} style={{ color: "#f9a826", fontSize: "24px" }} /> {car.transmission}
                  </span>

                  <span className=" d-flex align-items-center gap-1">
                    <FontAwesomeIcon icon={faClock} style={{ color: "#f9a826", fontSize: "24px" }} /> {car.year}
                  </span>
                </div>


                <div
                  className="d-flex align-items-center mt-3"
                  style={{ columnGap: "2.8rem", paddingTop:'25px' }}
                >
                  🛠️Garanție 1 An  motor+cutie de viteze <br />
                  🆘Asistență rutiera in toată țara <br />
                  🏪Verificare tehnică+eventuale reparații in service autorizat RAR <br />
                  🛣️Livrare pana la domiciliul clientului  <br />
                  🔢Numere provizorii <br />
                  🎥Apel video cu mașina dorită <br />
                  🔵Aprobare rapidă, in maxim 2 ore. <br />
                  ⚫️Se accepta persoane cu istoric negativ in biroul de credite <br />
                  ✅Aveți posibilitatea achiziționării autoturismului cu plata  cash , sau printr-un sistem avantajos de rate fixe. <br />
                  ✅ RATE FIXE pe toata perioada contractuala <br />
                  ✅ Posibilitate de rambursare anticipata <br />
                  ✅Finantare doar cu buletinul  <br />
                  ✅Vechime minim 3 luni la actualul angajator <br />
                  ✅Varsta intre 18 si 75 de ani <br />
                  ✅Se accepta venituri din Diurne / PFA / M.A.I. / M.A.P.N. / Chirii / Pensie/ indemnizație creștere copil <br />
                </div>
              </div>
            </Col>

            <Col lg={10} className="mt-5">
              <h5 className="mb-4 fw-bold">Informatii Suplimentare</h5>
              <textarea
                  readOnly
                  type="textarea"
                  className="textarea"
                  placeholder="description"
                  style={{ width: '100%', height: '350px' }}
                  value={car.description.replace(/,/g, '\n')}
              />
          </Col>
          </Row>
        </Container>
      </section>
    </Helmet>
  );
};

export default CarDetails;
