import React, { useRef } from "react";

import { Container, Row, Col } from "reactstrap";
import { Link, NavLink } from "react-router-dom";
import AutomatRulate from "../../assets/all-images/AutomatRulateBlack.png";
import BT from "../../assets/all-images/BT.png";
import Credius from "../../assets/all-images/Credius.png";
import Mogo from "../../assets/all-images/MOGO.png";
import TBI from "../../assets/all-images/TBI_Bank.png";
import "../../styles/header.css";

const navLinks = [
  {
    path: "/home",
    display: "Acasa",
  },
  {
    path: "/cars",
    display: "Masini",
  },
  {
    path: "/contact",
    display: "Contact",
  },
];

const Header = () => {
  const menuRef = useRef(null);

  const toggleMenu = () => menuRef.current.classList.toggle("menu__active");

  return (
    <header className="header">
      {/* ============ header top ============ */}
      <div className="header__top">
        <Container>
          <Row>
            <Col lg="6" md="6" sm="6">
              <div className="header__top__left">
                <span>Ai nevoie de ajutor?</span>
                <span className="header__top__help">
                  <i class="ri-phone-fill"></i> 0741.383.836
                </span>
              </div>
            </Col>

          
          </Row>
        </Container>
      </div>

      {/* =============== header middle =========== */}
     {/* =============== header middle =========== */}
      <div className="header__middle">
        <Container>
          <Row>
            <Col lg="3" md="3" sm="4" style={{ marginRight: "20px" }}>
              <div className="logo">
                <h1>
                  <Link to="/home" className="d-flex align-items-center gap-2">
                    <img src={AutomatRulate} alt="Automat Rulate" className="logo-image" />
                    <span style={{ fontSize: "20px", lineHeight: "1.2" }}>
                      Cumpara o masina cu incredere!
                    </span>
                  </Link>
                </h1>
              </div>
            </Col>

            <Col lg="1" md="3" sm="4">
              <div className="d-flex align-items-center gap-1">
                <h1 style={{ marginTop: "5px" }}>
                  <img src={BT} alt="Automat Rulate" className="logo-image" />
                </h1>
              </div>
            </Col>

            <Col lg="1" md="3" sm="4">
            <div className="d-flex align-items-center gap-1">
                <h1>
                    <img src={Credius} alt="Automat Rulate" className="logo-image" />
                </h1>
              </div>
            </Col>

            <Col lg="1" md="3" sm="4">
            <div className="d-flex align-items-center gap-1">
                <h1>
                    <img src={Mogo} alt="Automat Rulate" className="logo-image" />
                </h1>
              </div>
            </Col>

            <Col lg="1" md="3" sm="4" style={{ marginRight: "35px" }}>
              <div className="d-flex align-items-center gap-1">
                <h1>
                    <img src={TBI} alt="Automat Rulate" className="logo-image" />
                </h1>
              </div>
            </Col>
            
            <Col lg="2" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-1">
                <span>
                  <i class="ri-earth-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Arad, Buteni, <br/> Str. Orizontului</h4>
                </div>
              </div>
            </Col>

            <Col lg="2" md="3" sm="4">
              <div className="header__location d-flex align-items-center gap-2">
                <span>
                  <i class="ri-time-line"></i>
                </span>
                <div className="header__location-content">
                  <h4>Luni - duminica</h4>
                  <h6>09:00 - 20:00</h6>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>


      {/* ========== main navigation =========== */}

      <div className="main__navbar">
        <Container>
          <div className="navigation__wrapper d-flex align-items-center justify-content-between">
            <span className="mobile__menu">
              <i class="ri-menu-line" onClick={toggleMenu}></i>
            </span>

            <div className="navigation" ref={menuRef} onClick={toggleMenu}>
              <div className="menu">
                {navLinks.map((item, index) => (
                  <NavLink
                    to={item.path}
                    className={(navClass) =>
                      navClass.isActive ? "nav__active nav__item" : "nav__item"
                    }
                    key={index}
                  >
                    {item.display}
                  </NavLink>
                ))}
              </div>
            </div>

            <div className="nav__right">
              <div className="search__box">
                <input type="text" placeholder="Cauta" />
                <span>
                  <i class="ri-search-line"></i>
                </span>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </header>
  );
};

export default Header;
